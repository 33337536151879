document.addEventListener('turbolinks:load', function() {
    var placeOrderLink = document.querySelector('.place-order-link');
    if (placeOrderLink) {
      placeOrderLink.addEventListener('click', function(event) {
        event.preventDefault();
        var url = this.getAttribute('data-path');
        var form = document.querySelector('.message-modal-content form');
  
        fetch(form.action, {
          method: 'POST',
          body: new FormData(form),
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          credentials: 'same-origin'
        }).then(response => {
          if (response.ok) {
            window.location.href = url;
          } else {
            alert('There was an issue with your submission.');
          }
        }).catch(error => {
          console.error('Error:', error);
        });
      });
    }
  });
  